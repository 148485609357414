import React from "react"
import GalleryItem from "./GalleryItem"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "react-tabs/style/react-tabs.css"
import Masonry from 'react-masonry-component'
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const masonryOptions = {
    itemSelector: '.img-container__img',
    gutter: 25,
    fitWidth: true,
    transitionDuration: '0.2s',
    percentPosition: true,
};

const galleryLabels = {
    'agyak': 'Ágyak',
    'antik_butorok': 'Antik bútorok',
    'egyebek': 'Egyebek',
    'konyhabutorok': 'Konyhabútorok',
    'lepcsok': 'Lépcsők',
    'nyilaszarok': 'Nyílászárók',
    'szekrenyek': 'Szekrények'
};

// @todo: for dev purposese, change it before production
const apiUrl = '/api/gallery/';

class GalleryContent extends React.Component {

    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            photoIndex: 0,
            error: null,
            isLoaded: false,
            items: []
        }
        this.getGalleries();
    }

    componentDidMount() {
    }

    getGalleries() {
        axios.get(apiUrl).then((result) => {
            if (result.data.success) {
                this.setState({
                    isLoaded: true,
                    items: result.data.galleries
                });
            } else {
                this.setState({
                    isLoaded: true,
                    items: []
                });
            }
        }, (error) => {
            this.setState({
                isLoaded: true,
                error: error
            });
        });
    }

    render() {

        const { isOpen, error, isLoaded, items, photoIndex } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <h2><span>Galéria</span></h2>
                    <Tabs>
                        <TabList>
                            {
                                items.map((gallery, idx) => {
                                    return <Tab key={'tab-' + idx}>{galleryLabels[gallery.key]}</Tab>
                                })
                            }
                        </TabList>

                        {
                            items.map((gallery, idx) => {
                                return (
                                    <TabPanel key={'panel-' + idx}>
                                        <Masonry
                                            className={'img-container'}
                                            elementType={'div'}
                                            options={masonryOptions}
                                            disableImagesLoaded={false}
                                            onClick={() => { this.setState({ isOpen: true })}} 
                                            updateOnEachImageLoad={false}
                                        >
                                            {
                                                gallery.images.map((image, idx) => {
                                                    return <GalleryItem imgUrl={image.imgUrl} key={idx}  />
                                                })
                                            }
                                        </Masonry>
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={gallery.images[photoIndex] ? gallery.images[photoIndex].imgUrl : gallery.images[0].imgUrl}
                                                nextSrc={gallery.images[(photoIndex + 1) % gallery.images.length].imgUrl}
                                                prevSrc={gallery.images[(photoIndex + gallery.images.length - 1) % gallery.images.length].imgUrl}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        photoIndex: (photoIndex + gallery.images.length - 1) % gallery.images.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        photoIndex: (photoIndex + 1) % gallery.images.length,
                                                    })
                                                }
                                            />
                                        )}
                                    </TabPanel>
                                )
                            })
                        }

                    </Tabs>
                </div>
            )
        }
    }
}

export default GalleryContent
