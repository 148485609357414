import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import './style.scss'
//import './style.css';

ReactDOM.render(
    <App />,
    document.getElementById("root")
);
