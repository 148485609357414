import React from "react"
import $ from 'jquery'

import GalleryContent from "./gallery/GalleryContent"

function MainContent() {
    return (
        <main className="main">
            <section className="landing">
                <div className="black-overlay"></div>
                <div className="inner-container">
                    <div className="landing-logo">
                        <img src="/images/logo.svg" alt="Váci asztalos"/>
                    </div>
                </div>
            </section>
            <section className="about" id="about">
                <div className="inner-container">
                    <div>
                        <h2><span>Rólunk</span></h2>

                        <div className="half-content">
                            <div>
                                <p>Váci István vagyok, 25 éves szakmai tapasztalattal rendelkező asztalos.</p>

                                <p>Vállalom mindenféle bútor, nyílászáró, lépcső, kisebb tető elkészítését az alábbiak alapján:</p>

                                <ul>
                                    <li> - A szabványtól az egyedi méretig.</li>
                                    <li> - Az egyszerűtől az extráig.</li>
                                    <li> - A modern Olasz stílustól az Angol rusztikusig.</li>
                                    <li> - Forgácslapból, tömör fából.</li>
                                    <li> - Fenyőtől a tölgyig.</li>
                                    <li> - Meglévő bútorok stílusára utángyártást (akár fénykép alapján is).</li>
                                </ul>

                                <p>Fantáziarajz/egyedi ötlet alapján minden megvalósítható dolgot (pl. radiátor takaró, mérőórák ajtaja/burkolata, álló - és falra szerelhető fogas, íróasztal, akváriumdíszítő, polc, gyermekbútor, gyermekjáték stb.) legyártok.</p>

                                <p>Amennyiben felkeltettem érdeklődését, keressen bátran a kapcsolat részen lévő elérhetőségeim egyikén!</p>
                            </div>
                            <div>
                                <img src="/images/about.jpg" alt="Váci asztalos"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery" id="gallery">
                <div className="inner-container">
                    <div>
                        <GalleryContent />
                    </div>
                </div>
            </section>
            <section className="contact" id="contact">
                <div className="black-overlay-footer"></div>
                <div className="inner-container">
                    <div>
                        <h2><span>Kapcsolat</span></h2>
                        <p>+36-30/302-04-75, +36-30/944-06-70</p>
                        <p><a href="mailto:info@vaciasztalos.hu">info@vaciasztalos.hu</a></p>
                    </div>
                </div>
            </section>
        </main>
    )
}

/* Black overlay js */

$(function () {
    $(window).scroll(function () {
        var currentScrollTop = $(window).scrollTop();
        $('.black-overlay').css('opacity',currentScrollTop/$('.black-overlay').height());

    });
});

export default MainContent
