import React from "react"

function GalleryItem(props) {
    return (
        <div className='img-container__img'>
            <img src={props.imgUrl} alt="img"/>
            <p>{props.title}</p>
        </div>
    )
}

export default GalleryItem
