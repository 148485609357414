import React from "react"

 function Footer() {
     return (
         <footer className="footer">
            <div className="inner-container">
                <p>2019 © Váci István</p>
            </div>
         </footer>
     )
 }

export default Footer
