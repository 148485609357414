import React from "react"
import $ from 'jquery'
import classnames from 'classnames'

class Header extends React.Component {

    state = {
        activeTab: 1
    }

    handleClick(activeTab, e) {
        this.setState({'activeTab': activeTab})
    }

    render() {
        return (
            <header>
                <div className="inner-container">
                    <a href="/" id="logo">
                        <img src="/images/logo.svg" alt="Váci asztalos"/>
                    </a>
                    <nav>
                        <ul>
                            <li><a className={classnames('tab', { 'current-menu-item': this.state.activeTab === 1})} href="#about" onClick={this.handleClick.bind(this, 1)}>Rólunk</a></li>
                            <li><a className={classnames('tab', { 'current-menu-item': this.state.activeTab === 2})} href="#gallery" onClick={this.handleClick.bind(this, 2)}>Galéria</a></li>
                            <li><a className={classnames('tab', { 'current-menu-item': this.state.activeTab === 3})} href="#contact" onClick={this.handleClick.bind(this, 3)}>Kapcsolat</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}

/* Nav animate */

$(document).on('click', 'nav ul li a[href^="#"]', function (e) {
    e.preventDefault();

    var offset = $('header').height();

    $("nav ul li a.active").removeClass("active");
    $(this).addClass("active");

    // move page
    $('html,body').animate({scrollTop:$($(this).attr("href")).offset().top - offset + 2}, 500);
});

export default Header
